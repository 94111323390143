class Popup {
  constructor({ link }) {
    this.link = link;
    this.open = false;
  }

  init() {
    this.link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = this.link.getAttribute('data-popup-target');

      const target = document.getElementById(targetId);
      if (target) {
        document.addEventListener('click', (e) => {
          if (
            this.open &&
            e.target.getAttribute('data-popup-target') !== targetId
          ) {
            const clickedInsidePopup = !!e.target.closest(`#${targetId}`);
            if (!clickedInsidePopup) {
              target.classList.toggle('js-open');
              this.open = !this.open;
            }
          }
        });
        target.classList.toggle('js-open');
        this.open = !this.open;
      } else {
        console.warn(`The popup with the id ${targetId} does not exist.`);
      }
    });
  }
}

export default Popup;
