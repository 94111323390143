import 'normalize.css/normalize.css';
import 'bootstrap-4-grid/css/grid.min.css';
import '../scss/main.scss';

import { h, render } from 'preact';
import Popup from './modules/Popup';
import LoginForm from './modules/LoginForm';
import CodeForm from './modules/CodeForm';
import LeaderBoard from './modules/Leaderboard';
import './polyfills/closest';
import Typed from 'typed.js';

const navToggle = document.querySelector('[data-nav-toggle]');
const mobileNav = document.querySelector('.mobile-nav');
const closeButton = document.querySelector('[data-nav-close]');
navToggle.addEventListener('click', (e) => {
  e.preventDefault();

  mobileNav.classList.add('js-open');
});

closeButton.addEventListener('click', (e) => {
  e.preventDefault();

  mobileNav.classList.remove('js-open');
});

const popups = Array.from(document.querySelectorAll('[data-popup-target]'));
popups.forEach((el) => {
  const popup = new Popup({ link: el });
  popup.init();
});

const loginForms = Array.from(document.querySelectorAll('.login-form'));
loginForms.forEach((el) => {
  const loginForm = new LoginForm({
    form: el
  });
  loginForm.init();
});

const codeFormEl = document.querySelector('.dashboard-form');
if (codeFormEl) {
  const codeForm = new CodeForm({ form: codeFormEl });
  codeForm.init();
}

const typedContainer = document.getElementById('typed-1');

if (typedContainer) {
  const typed = new Typed('#typed-1', {
    strings: [
      '<p>Hello again.</p><p>So you think you’ve got what it takes to take on the BLK_BOX?</p><p>Excellent. I like a good challenge.</p><p>I’m so confident that your limits will be tested that if you finish, you’ll get a bespoke BLK_BOX coin* so you can show the world you beat me.</p><p>But hold on. I need to tell you a couple of things before you begin.</p><p class="small">*While the BLK_BOX is open to anyone who wants to take on my challenges, prizes will only be sent to UK residents.</p>'
    ],
    typeSpeed: 30,
    showCursor: false
  });
}

const leaderboard = document.querySelector('#leaderboard');
if (leaderboard) {
  render(<LeaderBoard />, leaderboard, leaderboard.lastChild);
}
