import { h, render, Component } from 'preact';
import axios from 'axios';

class LeaderBoard extends Component {
  state = {
    index: 0,
    items: [],
    isLastPage: true,
    isFirstPage: true
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    axios
      .get('/home/leaderboardlist', {
        params: {
          index: this.state.index
        }
      })
      .then(({ data }) => {
        this.setState(() => ({
          items: data.Items,
          isLastPage: data.IsLastPage,
          isFirstPage: data.IsFirstPage
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  renderBadges(completedStages) {
    const badges = [];
    for (let i = 1; i <= completedStages; i++) {
      badges.push(
        <img
          src="/assets/images/splat-badge.png"
          className="leaderboard__badge"
        />
      );
    }

    return badges;
  }

  render(props, state) {
    return (
      <div>
        <table className="leaderboard">
          {state.items.map((item) => (
            <tr>
              <td>{item.Position}:</td>
              <td>
                <img src={item.AvatarUrl} className="leaderboard__avatar" />
              </td>
              <td title={item.DisplayName}>
                {item.DisplayName.length > 20
                  ? item.DisplayName.substring(0, 20)
                  : item.DisplayName}
              </td>
              <td>{this.renderBadges(item.CompletedStages)}</td>
            </tr>
          ))}
        </table>
        {!state.isFirstPage && (
          <a
            className="leaderboard__pagination leaderboard__pagination--prev"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const index = state.index - 1 < 0 ? 0 : state.index - 1;
              this.setState(
                () => ({
                  index
                }),
                () => {
                  this.getData();
                }
              );
            }}
          >
            &lt; PREVIOUS 10
          </a>
        )}
        {!state.isLastPage && (
          <a
            className="leaderboard__pagination leaderboard__pagination--next"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const index = state.index + 1;
              this.setState(
                () => ({
                  index
                }),
                () => {
                  this.getData();
                }
              );
            }}
          >
            NEXT 10 &gt;
          </a>
        )}
      </div>
    );
  }
}

export default LeaderBoard;
