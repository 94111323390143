import axios from 'axios';
import serialize from 'form-serialize';
import { h, render } from 'preact';
import LeaderBoard from './Leaderboard';

class CodeForm {
  constructor({ form }) {
    this.form = form;
    this.url = this.form.getAttribute('action');
    this.button = this.form.querySelector('button');
    this.failMessage = document.querySelector('#code-response-fail');
    this.successMessage = document.querySelector('#code-response-success');
    this.leaderBoard = document.querySelector('#leaderboard');
  }

  init() {
    window.dataLayer = window.dataLayer || [];
    // render(<LeaderBoard />, this.leaderBoard, this.leaderBoard.lastChild);

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();

      this.failMessage.style.display = 'none';
      this.successMessage.style.display = 'none';

      this.form.classList.add('js-submitting');
      this.button.setAttribute('disabled', true);

      axios
        .post(this.url, serialize(this.form, { hash: true }))
        .then(({ data }) => {
          this.form.classList.remove('js-submitting');
          this.button.removeAttribute('disabled');
          if (data.Status === 'Fail') {
            this.failMessage.style.display = 'block';
          } else {
            window.dataLayer.push({
              event: `challenge${data.Stage}complete`
            });
            if (data.Stage === 7) {
              window.location.href = '/dashboard/complete';
            } else {
              this.successMessage.style.display = 'block';
              render(
                <LeaderBoard />,
                this.leaderBoard,
                this.leaderBoard.lastChild
              );
            }
          }
        })
        .catch((err) => {
          this.form.classList.remove('js-submitting');
          this.button.removeAttribute('disabled');
          console.log(err);
        });
    });
  }
}

export default CodeForm;
