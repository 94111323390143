import axios from 'axios';
import serialize from 'form-serialize';

class LoginForm {
  constructor({ form }) {
    this.form = form;
    this.url = this.form.getAttribute('action');
    this.elements = Array.from(this.form.elements);
    this.errors = false;
    this.errorSpace = this.form.querySelector('.login-form__error');
  }

  init() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();

      this.errors = false;
      this.errorSpace.innerText = '';

      const username = this.elements.find((x) => x.name === 'Username');
      const password = this.elements.find((x) => x.name === 'Password');

      username.classList.remove('js-error');
      password.classList.remove('js-error');

      if (username.value === '') {
        username.classList.add('js-error');
        this.errors = true;
      }
      if (password.value === '') {
        password.classList.add('js-error');
        this.errors = true;
      }

      if (!this.errors) {
        this.form.classList.add('js-submitting');
        const token = document.querySelector('.login-form').elements[0];

        axios
          .post(
            this.url,
            serialize(this.form, {
              hash: true
            }),
            { headers: { __RequestVerificationToken: token.value } }
          )
          .then(({ data }) => {
            if (data.status === undefined) {
              window.location.href = '/admin';
            } else if (data.status === 'ok') {
              window.location.href = '/dashboard';
            } else {
              this.errorSpace.innerText = data.message;
              this.form.classList.remove('js-submitting');
            }
          })
          .catch((err) => {
            console.log(err);
            this.form.classList.remove('js-submitting');
          });
      }
    });
  }
}

export default LoginForm;
